<template>
  <footer class="footer">
    <a
      href="https://api.whatsapp.com/send?phone=+9607944493&text=Hi"
      class="float"
      target="_blank"
    >
      <svg
        class="my-float"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 24 24"
        fill="#25D366"
      >
        <path
          d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zm.14 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z"
        />
      </svg>
    </a>

    <div class="footer-container">
      <!-- DISCOVER -->
      <div class="lg:w-1/4 md:w-1/2">
        <h3 class="footer-col-title">
          Discover
        </h3>
        <div class="flex text-xs font-thin">
          <ul class="text-highlight mr-10 ">
            <li class="uppercase pb-1.5">
              <a href="/honeymoon">Honeymoons</a>
            </li>
            <li class="uppercase pb-1.5">
              <a href="/special-offer">Special Offers</a>
            </li>
            <li class="uppercase pb-1.5"><a href="/resorts">Resorts</a></li>
            <li class="uppercase"><a href="#"></a></li>
          </ul>
          <ul class="text-highlight">
            <li class="uppercase pb-1.5"><a href="#">Surfing</a></li>
            <li class="uppercase pb-1.5"><a href="#">Family Retreats</a></li>
            <li class="uppercase pb-1.5"><a href="#">Resorts</a></li>
          </ul>
        </div>
      </div>
      <!-- / DISCOVER -->
      <!-- NEWSLETTER FORM -->
      <div class="lg:w-1/3 md:w-1/2 mt-10 md:mt-0">
        <h3 class="footer-col-title">
          Newsletter
        </h3>
        <p class="uppercase text-highlight text-xs">
          Join our exclusive family of 150,000 travellers who enjoy secret
          offers, discounts and packages.
        </p>
        <div class="flex mt-5">
          <input
            type="email"
            class="w-full px-5 text-xs"
            placeholder="ENTER YOUR EMAIL ADDRESS"
          /><button class="bg-highlight text-xs text-white px-6 py-3">
            Go
          </button>
        </div>
      </div>
      <!-- / NEWSLETTER FORM -->
      <!-- SOCIAL LINKS -->
      <div class="lg:w-1/4 md:w-1/2 mt-10 md:mt-0 flex flex-col items-end">
        <h3 class="footer-col-title-followus">
          Follow Us
        </h3>
        <div class="flex">
          <ul class="flex footer-social">
            <li class="w-10 h-10">
              <a href="https://www.instagram.com/amazingasiatravels/?hl=en">
                <svg
                  style="background-color: #a29c88; border-radius: 9999px; padding-right: 8px; padding-left: 8px;"
                  fill="white"
                  width="32px"
                  height="32px"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
                  />
                </svg>
              </a>
            </li>

            <li class="w-10 h-10">
              <a href="https://www.facebook.com/amazingasiatravelsandtours/">
                <svg
                  style="background-color: #a29c88; border-radius: 9999px; padding-right: 8px; padding-left: 8px;"
                  fill="white"
                  width="32px"
                  height="32px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                  stroke-width="0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
                  ></path>
                </svg>
              </a>
            </li>

            <li class="w-10 h-10">
              <a href="https://twitter.com/amazingasia_">
                <svg
                  style="background-color: #a29c88; border-radius: 9999px; padding-right: 8px; padding-left: 8px;"
                  fill="white"
                  width="32px"
                  height="32px"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Twitter</title>
                  <path
                    d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                  />
                </svg>
              </a>
            </li>

            <li class="w-10 h-10">
              <a
                href="https://www.youtube.com/channel/UCS3CChX0NY5R4tWvU58naJg/videos"
              >
                <svg
                  style="background-color: #a29c88; border-radius: 9999px; padding-right: 8px; padding-left: 8px;"
                  fill="white"
                  width="32px"
                  height="32px"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>YouTube</title>
                  <path
                    d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--/ SOCIAL LINKS -->
    </div>
  </footer>

  <div class="footer-socket">
    <div class="footer-socket-container ">
      <div>
        <img class="h-14 py-2" src="@/assets/img/logot.png" />

        <h3 class="font-thin text-xs text-highlight uppercase mb-2">
          PRIVACY POLICY - SITEMAP
        </h3>
      </div>
      <div>
        <h3 class="font-thin text-xs text-highlight uppercase mb-2">
          MEMBER OF
        </h3>
        <img class="w-40" src="footerlinks.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped lang="scss">
.float {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  color: white;
  text-align: center;

  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.footer-social {
  li:not(:last-child) {
    margin-right: 0.5rem;
  }
}

* {
  font-family: "Brown Regular";
  font-weight: 200;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Brown Regular"), url("/assets/Brown-Regular.ttf") format("ttf"),
    url("/assets/Brown-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Brown Regular";
  src: url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot");
  src: url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.svg#Brown Regular")
      format("svg");
}
</style>
